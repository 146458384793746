/* You can add global styles to this app-file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

$primary: mat.define-palette(mat.$grey-palette, 900, 700, 900);
$accent:  mat.define-palette(mat.$light-blue-palette, 600, 100, 900);

$warn:    mat.define-palette(mat.$red-palette, 600, 100, 900);

$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);

html, body {
  margin: 0;
  overflow-x: hidden;
}

.mat-card-header {
    .mat-card-title {
        //font-weight: 500;
    }
}

mat-icon.helper {
    font-size: 1.25em;
}

mat-card.mat-card {

    mat-card-content > section {
      h1 {
        font-size: 1em;
        font-weight: 500;
        margin: 0 0 0.75em 0;
        .helper {
          font-size: 1em;
        }
      }
      margin-bottom: 0.5em;
    }

    // MatTabGroups that are inside a mat-card should have special style
    > mat-tab-group:first-child {
        margin: -16px -16px 0 -16px;

        mat-tab-body {
            padding: 24px 24px 0 24px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            // Fixes flicking scrollbar when content animate, eg. expansion app-panel grow
            .mat-tab-body-content {
                width: 100%;
                //overflow: visible;
            }
        }
    }
}


mat-tab-header {
    .mat-tab-label {
        min-width: unset;
    }
}

mat-list-item {
    .mat-list-item-content {
        width: 100%;
    }
}

/*
 * Form related
 */

div.form-group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;

    mat-form-field, app-typeahead, app-chip-list-typeahead, mat-checkbox {
        flex-shrink: 1;
        padding: 5px;
        width: 25%;
        box-sizing: border-box;
        &.full-width {
            width: 100%;
        }
        &.half-width {
            width: 50%;
        }
    }

    app-typeahead  > mat-form-field,app-chip-list-typeahead > mat-form-field {
        width: 100%;
        padding: 0;
    }
}

.mat-placeholder-required.mat-form-field-required-marker {
    color: map-get(mat.$red-palette, 600);
}
